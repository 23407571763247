@import '../base.scss';


.nav-wrapper {
    z-index: 100;
    position: fixed;
    right:60px;
    top:60px;
    color:$primary-color;
    width: 50vw;
    height:45px;
    background-color: $secondary-color;
    display:flex;
    padding:0 1em;
    box-sizing: border-box;
    justify-content: center;
    border-radius: 45px;
    -webkit-border-radius: 45px;
    -moz-border-radius: 45px;
    -ms-border-radius: 45px;
    -o-border-radius: 45px;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) ;
    -webkit-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) ;
    -moz-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) ;
    -ms-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) ;
    -o-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) ;
    
    
    a{
        color:$primary-color;
        text-decoration: none;
    }
    ul{
        width:80%;
        display:grid;
        grid-template-columns: repeat(3,1fr);
        align-items: center;

        li{
            display: inline;
            list-style-type: none;
            font-variant: small-caps;
            font-size: 18px;
           
    }

        
    }

    .expand-icon{
        display: flex;
        align-self: center;
        font-size: 25px;
      
    }


}

@media(max-width:$layout-breakpoint-medium) {
    .nav-wrapper{
        width:90vw;
        right:5%;
        top:5%;
       ul{
           margin-right: 10px;
           li{
               font-size: 16px;
           }
       }
    }

}

.nav-shrink{
    width:45px;
    ul{
        display:none;
    }
}



