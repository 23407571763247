.solution{
    margin: 2em 0;
    display:flex;
    flex-direction: column;
    align-items: center;
    font-size: 21px;
    p{
        text-align: center;}
    img{
        width:150px;
    }
    .solution-explanation{
        font-size: 17px;
        text-align: center;
    }
    
}