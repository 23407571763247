/* @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;600&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700&family=Quicksand:wght@400;500;600;700&display=swap');
@import './base.scss';

*{
    margin:0;
    padding: 0;
}

body{
    font-family: 'Mulish', sans-serif;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0.5px;
    /* width:100vw; */
    overflow-x: hidden;
}

h1,h2,h3,h4,h5{
    font-family: 'Quicksand', sans-serif;
}

h1{
    font-size: 99px;
    font-weight: 400;
    text-transform: uppercase;
}

h2{
    font-size: 36px;
    font-weight: 500;
    text-transform: capitalize;

}

h3{
    font-size: 25px;
    font-weight: 400;
    text-transform: capitalize;
}

h4{
    font-size: 17px;
    font-weight: 400;
}

h5{
    font-weight: 600;
    line-height: 2;
}

.body-s{
    font-size: 14px;
    text-transform: uppercase;
}

.note-s{
    font-size: 14px;
    
}

button{
    width:200px;
    height:55px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    text-transform: capitalize;
    font:inherit;
    cursor: pointer ;
    outline:none;
}

.hidden{
    display:none !important; 
}

@media (max-width:$layout-breakpoint-medium){
    h1{
        font-size: 65px;
    }

}