@import '../base.scss';


.insight{
    border: 1px dashed $pink;
    padding:1em;
}

.customized-list{
    &>div{
        display: flex;
        align-items: center;
        svg{
            padding-right: 0.5em;
            color:$purple;
        }

    }
    
}

.user-flow{
    margin-top:3em;
}

.extra-margin{
    margin: 2em 0;
}

.insights-container{
    margin:6em 0;
}