@import '../base.scss';

.card-wrapper {
   
    width: 100%;

    a {
        display: block;
        color  : $primary-color;
    }
    transition: 1s;
    -webkit-transition: 1s;
    -moz-transition: 1s;
    -ms-transition: 1s;
    -o-transition: 1s;
}


.card-container {
    overflow: hidden;
    height  : 90vw;
    position: relative;
    width   : 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    
}

}

.card-info {
    padding : 0 1em;
    position: absolute;
    top     : 1em;
    h3{
        font-size: 18px;
    }

    p {
        font-size: 14px;
        padding-bottom: 1em;
    }

    h4 {
        display: none;
        padding: 4em 0;
    }

    button {
        display         : none;
        color           : $primary-color;
        background-color: rgba(0, 0, 0, 0.5);
        border          : none;
    }

}

.card-tag{
    display: none;
    
}


.one {
    background: $purple;

}

.two {
    background: $light-blue;

    // img {
    //     filter        : brightness(80%);
    //     -webkit-filter: brightness(80%);
    // }
}

.three {
    background: $blue;
}

.four {
    background: $pink;
}

.five {
    background: #353c6a;
}
.six{
    background: #ffce73;
}

.black-out{
    filter: brightness(20%);
    -webkit-filter: brightness(20%);

}


.progressive{
    animation: progressiveReveal 2s linear;
    -webkit-animation: progressiveReveal 2s linear;
}
  
  @keyframes progressiveReveal {
    0% {  opacity: 0; }
    100% { opacity: 1; }
  }
  

@media (min-width:$layout-breakpoint-medium) {
    .card-tag{
        display:block;
        padding-top: 400px;
        text-transform: capitalize;
        
    }
    .card-wrapper {
        width:50%;

        &:hover {
            width: 120%;
            img {
                display: none;
            }

            h4 {
                font-size: 17px;
                display: block;
            }

            button {
                display: block;
            }

            .card-tag{
                display: none;
            }

        }

    }

    .card-container {
        height: 600px;
    }

    .card-info {
       
        top     : 4em;
        left    : 2em;
        h3{
            font-size: 25px;
        }
    
        p {
            font-size: 17px;
            padding-bottom: 1em;
        }
       
    }
    

    .black-out{
        a{
            pointer-events: none;
            cursor: not-allowed;
        }
        &:hover{
            img {
                display: block;
            }
            width: 50%;
            button{
                display:none;
            }

        }
    }


}