@import '../base.scss';

.research-methods-pet{
    grid-template-columns: repeat(3,1fr);
    background: none;

}

.challenge{
    margin:6em 0;
    
}

.pet-videos{
    video{
        margin:4em 0;

    }
   
}