.videos{
    margin:2em 0;
    display: flex;
    justify-content: center;
}

video{
    width:100%;
    display:flex;
    justify-self:center
}

