@import '../base.scss';


.work-header-container {
    display        : flex;
    justify-content: center;
    align-items    : center;
    height         : 500px;
    background     : linear-gradient(225.85deg, $purple 0%, $pink 97.53%);

}

.work-header-content {
    text-align: center;
    color     : $primary-color;
p{
    line-height: 2;
}
  

    h1{
        padding:1.2rem 0;
    }


    &>div {
        // display: grid;
        // grid-auto-columns:;
        // column-gap: 4em;
        display:flex;
        justify-content: center;

        a,button {
            margin: 3em 1.5em 0 1.5em;
            width:200px;
            height:55px;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $primary-color;
            background: none;
            border: 1px solid $primary-color;
            transition: 0.5s;
            -webkit-transition: 0.5s;
            -moz-transition: 0.5s;
            -ms-transition: 0.5s;
            -o-transition: 0.5s;
            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;
            -ms-border-radius: 50px;
            -o-border-radius: 50px;
            text-decoration: none;
            font-weight: 400;
            svg{
                padding-right : 0.5em;
                padding-bottom: 0.1em;
            }

            &:hover{
                background:$purple;
            }
        }

    }

}

@media(max-width:$layout-breakpoint-small){
    .work-header-container{
        height: 100vh;
    }
    .work-header-content{
        &>div{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
        }
       
    }
    
}