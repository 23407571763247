// colors
$primary-color:#fffff8;
$secondary-color:#000;
$grey:rgb(99, 99, 99);
$purple:#8782E3;
$pink:#f09cad;
$light-pink:#f6b6b9;
$blue:#5e78d7;
$light-blue:#c3dadf;


// breakpoints


$layout-breakpoint-small:760px;
$layout-breakpoint-medium:1100px;