@import '../base.scss';


.stepper{
    svg{
        fill:$blue;
    }

    span{
        font-size: inherit; 
        color: $secondary-color;
        font-family: inherit;
        font-weight: 300;
        line-height: 2; 

    }


}

@media only screen and (max-width:$layout-breakpoint-small){
    .stepper{
        font-size: 10px;
    }
}