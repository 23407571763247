@import '../base.scss';

.project-container{
    margin-bottom: 10em;
}

.card-row{
    display:flex;
    // max-width: 1300px;
    // align-items: stretch;
    // background-color: $pink;
    position: relative;
    
}

.filters{
    button{
        background: none;
        transition-duration: 0.5s;
        border: 1px solid $blue;
        margin: 0 2em 2em 0;
        width:auto;
        height:auto;
        padding:0.5em 2em;
        color:$blue;
        outline: none;
        &:hover{
            background: $blue;
            color:$primary-color;
        }
        
       
    }
    .active{
        background: $blue;
        color:$primary-color;

    }

}


@media(max-width:$layout-breakpoint-medium){
    .card-row{
        flex-direction: column;
    }
}

