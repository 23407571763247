@import '../base.scss';

.work-content{
    max-width: 1200px;
    margin: 8em auto;
    h2{
        padding-bottom:2em;
        text-align: center;

    }

    .body-s{
        color:$grey;
        text-align: center;
        padding-bottom: 1em;
    }

    p{
        text-align: justify;
        
    }
    img{
        width:100%;
    }
    figcaption{
        text-align: center;
        font-size: 14px;
    }
}



section{
    max-width: 780px;
    margin:0 auto;
    a{
        
        font-weight: 600;
        font-size: inherit;
        color: $blue;
    }
    p{
        line-height: 2;
        font-stretch: ultra-expanded;
    }

    h3{
        margin:1em 0 1em 0;
        font-size: 95px;
        font-weight: 500;
        color:$grey;
        opacity: 0.4;
        // border-bottom: 2px dashed $pink;
    }
    span{
        color:$purple;
    }
    ol{
        padding-left: 1em;
        line-height: 2;

    }
    ul{
        list-style: none;
    }
    h4{
        // display: inline-block;
        text-transform: capitalize;
        margin:1em 0;
        font-size: 35px;
    }
    h5{
        font-size: 21px;
    }
}

.three-cols{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2em;
    div{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        img{
            width:50px;
        }
        p{
           line-height: 1.5;
           text-align: justify;
           hyphens: auto;
           -webkit-hyphens: auto;
           -moz-hyphens: auto;
           -ms-hyphens: auto;
}
        a{
            // text-decoration: none;
            color:inherit;
        }
    
    }
   
}

.fig-with-caption{
    display:flex;
    justify-content:space-evenly;
    figure{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
            width:120px;
        }
        figcaption{
            padding-top: 1em;
            font-size: 16px;

        }
        
    }
}

iframe{
    width:100%;
    height:500px;
}

.carousel{
    width:100%;
    border: none;
    
    img{
        height: 500px;
        object-fit: contain;
    }
    button{
        width: 100px;
        height:100px;
        opacity: 60%;

    }
    // button{
    //     width:100px;
    //     height: 100px;
    // }
}

.live-demo-container{
    display: block;
}
.live-demo-btn{
    text-decoration: none;
    color: $primary-color;
    background-color: $pink;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    padding:1em 4em;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    margin-top: 3em;
    font-weight: 400;
    svg{
        padding-right: 0.2em;
    }
    transition-duration: 0.5s;

    &:hover{
        background-color: #cc7b8c;
    }
}

.btn-contour{
    border: 2px solid $pink;
    background: none;
    color:$pink;
    &:hover{
        background-color: $pink;
        color:$primary-color;
    }
}

.highlight{
   font-size: 18px;
   margin: 2em 0
}

// jump to tech section button
.about{
    a{
        display: flex;
        justify-content: center;
        text-decoration: none;
        color:$primary-color;
        background-color: $blue;
        border: 1px solid $blue;
        padding:1em 2em;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        margin-bottom: 4em;
        transition-duration: 0.2s;
        font-weight: 400;
        &:hover{
            background-color: #4c62b1;
        }
    }

    
}

.zoom-image{
    margin:4em 0;
    width:100%;
    outline:none;
}


.videos{
    margin:2em 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

video{
    width:100%;
    display:flex;
    justify-self:center
}

p.design-question{
    margin: 1em 0 2em 0;
    font-size: 35px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    padding:0 2em;
    text-align: center;
}


.progressive-cover{
    clip-path: inset(0);
    -webkit-clip-path:inset(0);
    filter:blur(30px);
    -webkit-filter:blur(30px);
}
  
.progressive-cover-done{
    clip-path: inset(0);
    -webkit-clip-path:inset(0);
    animation: progressiveReveal2 1s linear;
    -webkit-animation: progressiveReveal2 1s linear;
}
  @keyframes progressiveReveal2 {
    0% { filter:blur(30px); -webkit-filter:blur(30px); }
    100% { filter:blur(0px) ; -webkit-filter:blur(0px) ; }
  }

@media (max-width:$layout-breakpoint-medium){

    section{
        h3{
            font-size: 59px;
        }
        h4{
            font-size: 25px;
        }
        max-width: unset;
        margin:0 10%;
    }
    p.design-question{
        padding:1em;
        font-size: 25px;
        text-align: center;
    }

}

.light-shadow{
    box-shadow: 0px 10px 30px rgba(112, 108, 138, 0.14);
    padding: 2em 1em;
    margin:3em 0;
    box-sizing: border-box;
    
}

@media (max-width:$layout-breakpoint-small){
    .fig-with-caption{
        flex-direction: column;
    }
    .three-cols{
        grid-template-columns: 1fr;
    }
    section{
        margin:0 5%;
    }

}


