
.issue-cause-title{
    
    span{
        color:#000;
        padding-left: 0.5em;
    }
    display: flex;
    align-items: center;
    
}

.issue-cause{
    line-height: 2;
    ul{
        padding-left: 4em;
        list-style-type: disc;
    }

    svg{
        opacity: 0.5;
    }
    
}